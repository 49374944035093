import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {ModalController, ToastController} from '@ionic/angular';
// @ts-ignore
import {ERR} from 'ngx-gesture-password';
import {GeneralService} from 'ng-upgrade-common';
import {GlobalVariable} from '../../config/variable';

@Component({
  selector: 'app-gesture-pass',
  templateUrl: './gesture-pass.component.html',
  styleUrls: ['./gesture-pass.component.scss'],
})
export class GesturePassComponent implements OnInit {
  globalVariable = GlobalVariable;
  @ViewChild('gesture') gesture: any; // 图片区域
  pwd: string = localStorage.getItem('_gesture');
  @Input() type = 'recorder'; // recorder 设置手势密码,//check 输入手势密码
  @Input() isShowForgest: boolean;
  @Input() isShowClose: boolean;
  @Input() isUpdateGesturePass: boolean;
  options: any;
  title: string;

  constructor(private toastCtrl: ToastController,
              private modalController: ModalController,
              private generalService: GeneralService) {
  }

  // 显示 toast
  async presentToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      position: 'top',
      color: 'warning',
      duration: 1200
    });
    await toast.present();
  }

  // 关闭弹窗
  closeGesture(type?: string) {
    if (type === 'close') {
      this.modalController.dismiss();
      return;
    }
    this.modalController.dismiss().then(() => {
      this.generalService.publishEvent('logout', {});
    });
  }

  onError(data: any) {
    console.log(data);
  }


  onChecked(data: any) {
    switch (data.err) {
      case ERR.NOT_ENOUGH_POINTS:
        this.presentToast('密码至少4个点');
        break;
      case ERR.PASSWORD_MISMATCH:
        this.presentToast('密码错误');
        break;
      default:
        if (this.isUpdateGesturePass) {
          this.type = 'recorder';
          this.title = '请设置新的手势密码';
          return;
        }
        this.modalController.dismiss({}, 'save');
        break;
    }
  }

  onBeforeRepeat(data: any) {
    switch (data.err) {
      case ERR.NOT_ENOUGH_POINTS:
        this.presentToast('密码至少4个点');
        break;
      default:
        this.presentToast('请再次绘制相同图案');
        break;
    }
  }

  onAfterRepeat(data: any) {
    switch (data.err) {
      case ERR.NOT_ENOUGH_POINTS:
        this.presentToast('密码至少4个点');
        break;
      case ERR.PASSWORD_MISMATCH:
        this.presentToast('两次密码不匹配');
        break;
      default:
        this.presentToast('新密码已经生效');
        localStorage.setItem('_gesture', data.result);
        this.modalController.dismiss({}, 'save');
        break;
    }
  }

  ngOnInit() {
    if (this.type === 'recorder') {
      this.title = '请设置手势密码';
    } else if (this.type === 'check' && this.isUpdateGesturePass) {
      this.title = '请输入旧的手势密码';
    } else if (this.type === 'check' && !this.isUpdateGesturePass) {
      this.title = '请输入手势密码';
    }
  }

  ionViewDidEnter() {
    console.log(this.gesture);
    this.gesture.ngOnInit();
    this.gesture.render();
  }

  ionViewWillEnter() {
    this.options = {
      bgColor: '#fff',
      focusColor: '#e06555',
      fgColor: '#d6dae5',
      num: 3,
      innerRadius: 20,
      outerRadius: 20,
      touchRadius: 70,
      render: true,
      min: 4,
      passwords: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
    };
  }

}


export const decorateEnv = (env) => {
    env.build.buildDate = '20231031133933';
    env.build.buildDateEpoch = 1698759573012;
    env.build.buildRevision = '8d16fd11f08a3636657244f23fd80dcefdfa3f7d';
    env.build.project = 'abbottamd';
    env.build.part = 'rep';
    env.build.deployment = 'prd';
    env.build.viewport = 'wow';
    env.apiRoot = 'https://amdsfe-api.abbott.com.cn';
    env.production = 'true';
    env.variables.corpId = 'wx7dbaf21fd68d3c7a';
    env.variables.mapApiKey = 'ee95e52bf08006f63fd29bcfbcf21df0';
};

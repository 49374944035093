import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {XskBasisModule} from 'ng-upgrade-basis';
import {IonicModule} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {ApiModule} from 'ng-upgrade-api';
import {NgxAmapModule} from 'ngx-amap';
import {environment} from '../environments/environment';
import {NgZorroAntdMobileModule} from 'ng-zorro-antd-mobile';
import {NgxEchartsModule} from 'ngx-echarts';
import * as echarts from 'echarts';
import {SwitchTerritoryModalModule} from './pages/login/switch-territory-modal/switch-territory-modal.module';
import {GesturePassModule} from './modals/gesture-pass/gesture-pass.module';
import {MomentModule} from 'ngx-moment';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    XskBasisModule,
    IonicModule.forRoot({
      mode: 'ios',
      backButtonText: ''
    }),
    AppRoutingModule,
    ApiModule,
    NgxAmapModule.forRoot({
      // apiKey: environment.variables.mapApiKey
      apiKey: 'de82edae47fe09a620b1666ec901d228'
    }),
    NgZorroAntdMobileModule,
    NgxEchartsModule.forRoot({
      echarts
    }),
    SwitchTerritoryModalModule,
    GesturePassModule,
    MomentModule
  ],
  exports: [
    BrowserModule,
    XskBasisModule,
    IonicModule,
    AppRoutingModule,
    ApiModule,
    NgxAmapModule,
    NgZorroAntdMobileModule,
    NgxEchartsModule,
    SwitchTerritoryModalModule,
    GesturePassModule,
    MomentModule
  ],
})

export class SharedModule {
}

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalVariable} from '../../config/variable';
import {HttpClient} from '@angular/common/http';
import {AppUserService} from '../../services/app-user.service';
import {GeneralService} from 'ng-upgrade-common';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-authentication-landing',
  template: `
    <div class="auth-info">
      <div class="record-box">
        <div class="wrapper">
          <div class="loader align-center">
            <div class="loader-inner line-scale-pulse-out">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <p>正在验证身份，请稍后……</p>
      </div>
    </div>
  `,
  styleUrls: ['./authentication-landing.component.scss']
})
export class AuthenticationLandingComponent implements OnInit, OnDestroy {
  globalVariable = GlobalVariable;
  routeSub: any;

  constructor(private router: Router,
              private activeRouter: ActivatedRoute,
              private http: HttpClient,
              private appUserService: AppUserService,
              public changeDetectorRef: ChangeDetectorRef,
              private generalService: GeneralService) {
  }

  ngOnInit() {
    // 停留1秒，太快code取不到身份
    setTimeout(() => {
      this.routeSub = this.activeRouter.queryParams.subscribe(queryParams => {
        // KV参数Code
        const corpid = environment.variables.corpId;
        const code = queryParams.code; // 腾讯的授权code
        const url = encodeURI(window.location.href);
        // const url = window.location.href.split('?')[0];
        if (code) {
          // 用code换取当前身份
          this.appUserService.LoginForWechat({Code: code}).subscribe(() => {
            this.globalVariable.authenticationStatus.status = 'success';
            this.generalService.publishEvent('landingSuccess', {});
          }, () => {
            this.globalVariable.authenticationStatus.status = 'aborted';
          });
        } else {
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corpid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        }
      }, (e) => {
      });
    }, 500);
  }


  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

}
